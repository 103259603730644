import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  DesktopOutlined,
  SnippetsOutlined,
  BarcodeOutlined,
  CarOutlined,
  UsergroupAddOutlined,
  MoneyCollectOutlined,
  FileAddOutlined,
  BarChartOutlined,
  ToolOutlined,
  ContactsOutlined,
  ProfileOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

import { ROUTES } from "../../../constants";
import { SiderTrigger } from "../../../components/SiderTrigger";
import { useAuth } from "../../../hooks/useAuth";

const isDevMode = process.env.NODE_ENV === "development";
const menuItems = [
  {
    key: "home",
    label: <Link to={ROUTES.home}>Home</Link>,
    icon: <DesktopOutlined />,
    visibility: ["admin", "staff", "manager", "finance"],
  },
  {
    key: "orders",
    label: <Link to={ROUTES.orders}>Orders</Link>,
    icon: <SnippetsOutlined />,
    visibility: ["admin", "manager", "staff"],
  },
  {
    key: "lineItems",
    label: <Link to={ROUTES.lineItems}>LineItems</Link>,
    icon: <ProfileOutlined />,
    visibility: ["admin", "manager", "staff"],
  },
  {
    key: "products",
    label: <Link to={ROUTES.products}>Products</Link>,
    icon: <BarcodeOutlined />,
    visibility: ["admin", "manager", "staff"],
  },
  {
    key: "buyer",
    label: <Link to={ROUTES.buyer}>Buyer</Link>,
    icon: <ContactsOutlined />,
  },
  // {
  //   key: "allocation",
  //   label: <Link to={ROUTES.allocation}>Allocate</Link>,
  //   icon: <BarcodeOutlined />,
  //   visibility: ["admin", "manager"],
  // },
  {
    key: "Quotations",
    label: <Link to={ROUTES.quotations}>Quotations</Link>,
    icon: <ProfileOutlined />,
    visibility: ["admin", "manager"],
  },
  {
    key: "logistic",
    label: <Link to={ROUTES.logistic}>Logistic</Link>,
    icon: <CarOutlined />,
    visibility: ["admin", "staff", "manager"],
  },
  {
    key: "customers",
    label: "Customers",
    icon: <UsergroupAddOutlined />,
    children: [
      { label: <Link to={ROUTES.customers}>Customers</Link>, key: "regularCustomers" },
      { label: <Link to={ROUTES.franchises}>Franchises</Link>, key: "franchises" },
    ],
    visibility: ["admin", "staff", "manager", "finance"],
  },
  // {
  //   key: "payments",
  //   label: <Link to={ROUTES.payments}>Payments</Link>,
  //   icon: <MoneyCollectOutlined />,
  //   visibility: ["admin", "finance"],
  // },
  // {
  //   key: "/payments/new",
  //   label: <Link to={ROUTES.paymentsNew}>New payment</Link>,
  //   icon: <MoneyCollectOutlined />,
  //   visibility: ["admin", "finance"],
  // },
  // {
  //   key: "statements",
  //   label: <Link to={ROUTES.statements}>Statements</Link>,
  //   icon: <FileAddOutlined />,
  //   visibility: ["admin", "finance"],
  // },
  // {
  //   key: "invoices",
  //   label: <Link to={ROUTES.invoices}>Invoices</Link>,
  //   icon: <SnippetsOutlined />,
  //   isDevOnly: false,
  //   visibility: ["admin", "finance"],
  // },
  // {
  //   key: "expenses",
  //   label: <Link to={ROUTES.expenses}>Expenses</Link>,
  //   icon: <SnippetsOutlined />,
  //   visibility: ["admin", "finance", "staff", "manager"],
  // },
  // {
  //   key: "analytics",
  //   label: <Link to={ROUTES.analytics}>Analytics</Link>,
  //   icon: <BarChartOutlined />,
  //   isDevOnly: true,
  //   visibility: ["admin", "finance", "staff", "manager"],
  // },
  {
    key: "orders-report",
    label: <Link to="/orders-report">Orders report</Link>,
    icon: <BarChartOutlined />,
    visibility: ["admin", "manager"],
  },
  {
    key: "settings",
    label: "Settings",
    icon: <SettingOutlined />,
    children: [
      { label: <Link to={"/logistic-setup"}>Logistic setup</Link>, key: "logistic-setup" },
    ],
    visibility: ["admin", "staff", "manager"],
  },
  {
    key: "sales-portal",
    label: <Link to="/sales-portal">Sales portal</Link>,
    icon: <MoneyCollectOutlined />,
    isDevOnly: true,
    visibility: ["admin", "sales"],
  },
  {
    key: "playground",
    label: <Link to={ROUTES.playground}>Playground</Link>,
    icon: <ToolOutlined />,
    isDevOnly: true,
    visibility: ["admin", "finance", "staff", "manager"],
  },
];

const salesColumns = [
  {
    key: "sales-portal",
    label: <Link to="/sales-portal">Customers</Link>,
    icon: <UsergroupAddOutlined />,
  },
];

export const Sidebar = ({ collapsed, setCollapsed }) => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(["/"]);
  const { user } = useAuth();
  const [items, setItems] = useState([]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    user?.role === "sales"
      ? setItems(salesColumns)
      : setItems(
          menuItems.reduce((prev, { key, label, icon, isDevOnly, children }) => {
            if (!isDevMode && isDevOnly) {
              // production, donot include isDevOnly items
              return prev;
            }
            // production, isDevOnly === false
            return [...prev, { key, label, icon, children }];
          }, [])
        );
  }, [user]);

  useEffect(() => {
    if (typeof window !== "undefined" && window.visualViewport.width <= 768) {
      setCollapsed(true);
    }
  }, [setCollapsed]);

  useEffect(() => {
    const { pathname } = location;

    if (pathname === "/") {
      setSelectedKeys(["home"]);
    } else {
      if (pathname === "/payments/new") {
        setSelectedKeys(["/payments/new"]);
      } else {
        const prefix = pathname.split("/")[1];
        setSelectedKeys([prefix]);
      }
    }
  }, [location]);

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
      width={collapsed ? "64px" : "156px"}
      style={{
        overflow: "auto",
        height: "calc(100% - 3.5rem)",
        position: "fixed",
        left: 0,
        top: "3.5rem",
        bottom: 0,
        background: "#fff",
        borderRight: "0.33px solid lightgrey",
      }}
      theme="light"
      trigger={<SiderTrigger collapsed={collapsed} />}
    >
      <Menu items={items} selectedKeys={selectedKeys} mode="inline" />
    </Layout.Sider>
  );
};
